import React, { forwardRef } from "react";
import cx from "classname";
import * as styles from "./styles.module.scss";

function Input({ ref, id, label, errorHint, className, ...props }) {
  const htmlId = id || props.name;
  return (
    <div className={className}>
      {label && (
        <label htmlFor={htmlId} className={styles.label}>
          {label}
        </label>
      )}
      <input
        ref={ref}
        id={htmlId}
        className={cx(styles.input, props.inputClassName)}
        {...(errorHint && { "aria-invalid": "true" })}
        {...props}
      />
      {errorHint && <span className={styles.errorHint}>{errorHint}</span>}
    </div>
  );
}

export default forwardRef((props, ref) => <Input ref={ref} {...props} />);

export function Textarea({ ref, id, label, errorHint, className, ...props }) {
  const htmlId = id || props.name;
  return (
    <div className={className}>
      {label && (
        <label htmlFor={htmlId} className={styles.label}>
          {label}
        </label>
      )}
      <textarea
        ref={ref}
        id={htmlId}
        className={cx(styles.input, props.inputClassName)}
        {...props}
      />
      {errorHint && <span className={styles.errorHint}>{errorHint}</span>}
    </div>
  );
}
