import React, { useState } from "react";
import Input, { Textarea } from "../../components/Input";
import Body from "../../components/Body";
import * as styles from "./styles.module.scss";
import cx from "classname";

const notEmpty = (value) => String(value).trim() !== "";

const validationRules = {
  firstName: notEmpty,
  lastName: notEmpty,
  email: notEmpty,
  phone: notEmpty,
  company: notEmpty,
  title: notEmpty,
  jobFunction: notEmpty,
  country: notEmpty,
  numberOfEmployees: notEmpty,
  communicationConsent: (v) => v === "true"
};
function validate(lead) {
  const output = {};

  Object.keys(validationRules).forEach((key) => {
    const value = lead[key];
    if (!validationRules[key]) {
      output[key] = true;
    } else {
      output[key] = validationRules[key](value);
    }
  });

  output.isValid = Object.values(output).every((v) => v === true);
  return output;
}

export default function Form({ data, onSuccess }) {
  const [lead, setLead] = useState(null);
  const validations = lead ? validate(lead) : {};

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    const newLead = {};
    for (const key of formData.keys()) {
      newLead[key] = String(formData.get(key));
    }

    const updatedValidations = validate(newLead);

    if (updatedValidations.isValid) {
      window?.analytics?.track && window.analytics.track("B2B Lead", newLead);
      onSuccess && onSuccess();
      form.reset();
    } else {
      const inputName = Object.entries(updatedValidations).find(
        ([key, value]) => {
          if (value === false) {
            return true;
          }
          return false;
        }
      );

      if (inputName) {
        const input = form.querySelector(`[name="${inputName[0]}"]`);
        if (input) {
          input.focus();
        }
      }
    }

    setLead(newLead);
    return false;
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className={styles.formGrid}>
        <Input
          name="firstName"
          label="First Name"
          errorHint={
            validations.firstName === false && "Please enter your first name"
          }
        />
        <Input
          name="lastName"
          label="Last Name"
          errorHint={
            validations.lastName === false && "Please enter your last name"
          }
        />
        <Input
          name="email"
          label="Email Address"
          type="email"
          errorHint={
            validations.email === false && "Please enter your email address"
          }
        />
        <Input
          name="phone"
          label="Phone"
          type="tel"
          errorHint={validations.phone === false && "Please enter your phone"}
        />
        <Input
          name="company"
          label="Company / Organization"
          errorHint={
            validations.company === false && "Please enter your company"
          }
        />
        <Input
          name="title"
          label="Title"
          errorHint={validations.title === false && "Please enter your title"}
        />
        <Input
          name="jobFunction"
          label="Job Function"
          errorHint={
            validations.jobFunction === false && "Please enter your title"
          }
        />
        <Input
          name="country"
          label="Country"
          errorHint={
            validations.country === false && "Please enter your country"
          }
        />
        <Input
          name="numberOfEmployees"
          label="Number of employees"
          type="number"
          errorHint={
            validations.numberOfEmployees === false &&
            "Please enter number of employees"
          }
        />
      </div>
      <Textarea
        label="Tell us more"
        className={styles.detailsInput}
        name="details"
      ></Textarea>

      <label
        className={cx(styles.checkboxContainer, {
          [styles.invalid]: validations.communicationConsent === false
        })}
      >
        <input
          type="checkbox"
          name="communicationConsent"
          className={styles.checkbox}
          value="true"
        />
        <span className={styles.checkboxText}>{data.agreeCommunications}</span>
        <Body
          richText={data.privacyDisclaimer}
          className={styles.privacyDisclaimer}
        />
      </label>
      <button className={cx("button yellow mobileFull")}>{data.cta}</button>
    </form>
  );
}
