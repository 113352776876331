// extracted by mini-css-extract-plugin
export var body = "styles-module--body--keKRj";
export var checkbox = "styles-module--checkbox--n3Kmv";
export var checkboxContainer = "styles-module--checkboxContainer--XtrDs";
export var checkboxText = "styles-module--checkboxText--lNgw2";
export var contactFormSuccess = "styles-module--contactFormSuccess--jcc0C";
export var container = "styles-module--container--byMxk";
export var detailsInput = "styles-module--detailsInput--K1YH8";
export var fadeIn = "styles-module--fadeIn--yJWHZ";
export var fadeInAndMoveUp = "styles-module--fadeInAndMoveUp--3qCdK";
export var formGrid = "styles-module--formGrid--ViScz";
export var grid = "styles-module--grid--R+9D9";
export var headline = "styles-module--headline--Y27JF";
export var imageContainer = "styles-module--imageContainer---f98o";
export var invalid = "styles-module--invalid--Vrd+a";
export var left = "styles-module--left--uSprB";
export var privacyDisclaimer = "styles-module--privacyDisclaimer--7vzJ0";
export var right = "styles-module--right--hbwM5";
export var scaleUp = "styles-module--scaleUp--gqZzM";
export var slideIn = "styles-module--slideIn--TU+uV";