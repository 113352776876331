import React from "react";
import SiteGrid from "../../components/SiteGrid";
import SiteGutter from "../../components/SiteGutter";
import * as styles from "./styles.module.scss";
import cx from "classname";
import Body from "../../components/Body";
import bg from "./bg.jpg";
import Link from "../../components/Link";
import Headline from "../../components/Headline";

export default function Hero({ data }) {
  return (
    <div className={styles.container}>
      <SiteGutter light>
        <SiteGrid>
          <div className={styles.grid}>
            <div className={styles.left}>
              <div>
                <div className={styles.eyebrow}>{data.eyebrow}</div>
                <Headline className={styles.headline} type="s32">
                  {data.headline}
                </Headline>
                <Body className={styles.body} richText={data.body} />
                <Link
                  url={data.cta.url}
                  className={cx(styles.cta, "button yellow mobileFull")}
                  label={data.cta.label}
                />
              </div>
            </div>
            <div className={styles.right}>
              <img src={bg} alt="" className={styles.rightImg} />
            </div>
          </div>
        </SiteGrid>
      </SiteGutter>
    </div>
  );
}
