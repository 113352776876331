// extracted by mini-css-extract-plugin
export var body = "styles-module--body--K1Z0g";
export var container = "styles-module--container--z0asp";
export var content = "styles-module--content--mjSD2";
export var fadeIn = "styles-module--fadeIn--LuDQd";
export var fadeInAndMoveUp = "styles-module--fadeInAndMoveUp--WX13s";
export var grid = "styles-module--grid--xEZYt";
export var headline = "styles-module--headline--GR3FM";
export var link = "styles-module--link--eeIg2";
export var mobileScreenshotContainer = "styles-module--mobileScreenshotContainer--eBicb";
export var orb = "styles-module--orb--qZ5T9";
export var scaleUp = "styles-module--scaleUp--Re-ol";
export var screenshotContainer = "styles-module--screenshotContainer--DAhCL";
export var slideIn = "styles-module--slideIn--O8dfu";