// extracted by mini-css-extract-plugin
export var container = "styles-module--container--hCcQn";
export var fadeIn = "styles-module--fadeIn--ACUqC";
export var fadeInAndMoveUp = "styles-module--fadeInAndMoveUp--FIwJU";
export var grid = "styles-module--grid--iAeOi";
export var headline = "styles-module--headline--ni0me";
export var item = "styles-module--item--+m+wY";
export var itemHeadline = "styles-module--itemHeadline--dlfBo";
export var percentage = "styles-module--percentage--V5hFU";
export var scaleUp = "styles-module--scaleUp--4K4O7";
export var slideIn = "styles-module--slideIn--hwKcB";
export var statIcon = "styles-module--statIcon--lHsCB";