// extracted by mini-css-extract-plugin
export var body = "styles-module--body--jH-Px";
export var container = "styles-module--container--p7luy";
export var cta = "styles-module--cta--yW-E0";
export var eyebrow = "styles-module--eyebrow--DKqQm";
export var fadeIn = "styles-module--fadeIn--xPyEd";
export var fadeInAndMoveUp = "styles-module--fadeInAndMoveUp--zTJa4";
export var grid = "styles-module--grid--JycX4";
export var headline = "styles-module--headline--bM2bw";
export var left = "styles-module--left--5-PTo";
export var right = "styles-module--right--fjmc6";
export var rightImg = "styles-module--rightImg--IMVjL";
export var scaleUp = "styles-module--scaleUp--uB+FD";
export var slideIn = "styles-module--slideIn--LT+oc";