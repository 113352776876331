// extracted by mini-css-extract-plugin
export var b2b = "styles-module--b2b--jVGeX";
export var body = "styles-module--body--7aEw9";
export var cta = "styles-module--cta--oqev+";
export var entry = "styles-module--entry--jY+ZG";
export var fadeIn = "styles-module--fadeIn--YriRz";
export var fadeInAndMoveUp = "styles-module--fadeInAndMoveUp--dU6pS";
export var footer = "styles-module--footer--MLhcA";
export var headline = "styles-module--headline--kns2m";
export var large = "styles-module--large--aPqDz";
export var ratingContainer = "styles-module--ratingContainer--UEry-";
export var scaleUp = "styles-module--scaleUp--CbPkK";
export var slideIn = "styles-module--slideIn--kN2vW";