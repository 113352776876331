import React from "react";
import SiteGrid from "../../components/SiteGrid";
import SiteGutter from "../../components/SiteGutter";
import * as styles from "./styles.module.scss";
import Headline from "../../components/Headline";

export default function RobustResults({ data }) {
  return (
    <div className={styles.container}>
      <SiteGutter light>
        <SiteGrid>
          <Headline className={styles.headline} type="s32f">
            {data.headline}
          </Headline>
          <div className={styles.grid}>
            {["BetterSleep", "LessStress", "MoreFocus"].map((key) => (
              <div className={styles.item} key={key}>
                <img
                  src={data[key].image.image.file.url}
                  alt={data[key].image.image.title}
                  className={styles.statIcon}
                />
                <div className={styles.percentage}>{data[key].percentage}</div>
                <Headline className={styles.itemHeadline} type="s16">
                  {data[key].description}
                </Headline>
              </div>
            ))}
          </div>
        </SiteGrid>
      </SiteGutter>
    </div>
  );
}
