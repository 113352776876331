import React from "react";
import SiteGrid from "../../components/SiteGrid";
import SiteGutter from "../../components/SiteGutter";
import * as styles from "./styles.module.scss";
import Body from "../../components/Body";
import Headline from "../../components/Headline";
import Link from "../../components/Link";
import ReviewCard from "../../components/ReviewCard";
import cx from "classname";
import Slider from "../../components/Slider";

const defaultOptions = {
  arrows: false,
  autoplay: false,
  pagination: true,
  type: "slide",
  perPage: 1,
  gap: 32,
  width: "calc(100vw - 54px)"
};

export default function Testimonials({ data }) {
  return (
    <div className={styles.container}>
      <SiteGutter light>
        <SiteGrid>
          <div className={styles.grid}>
            <div>
              <Headline className={styles.headline} type="s32f">
                {data.headline}
              </Headline>
              <Body richText={data.body} />
              <Link
                className={cx("button yellow mobileFull", styles.cta)}
                url={data.cta.url}
              >
                {data.cta.label}
              </Link>
            </div>
            <div>
              <div className={styles.testimonials}>
                <Slider
                  options={defaultOptions}
                  className={styles.sliderContainer}
                >
                  {Object.values(data.TestimonialList).map((item, index) => (
                    <div className={styles.item} key={index}>
                      <ReviewCard
                        rating={item.ratings}
                        theme="b2b"
                        body={item.body}
                        footer={item.citation}
                        className={styles.reviewCard}
                        bodyClassName={styles.reviewCardBody}
                      />
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </SiteGrid>
      </SiteGutter>
    </div>
  );
}
