// extracted by mini-css-extract-plugin
export var container = "styles-module--container--swzR1";
export var cta = "styles-module--cta--nvDph";
export var fadeIn = "styles-module--fadeIn--mjLJi";
export var fadeInAndMoveUp = "styles-module--fadeInAndMoveUp--FVxnM";
export var grid = "styles-module--grid--bzgHF";
export var headline = "styles-module--headline--KZlNl";
export var item = "styles-module--item--Ks3EW";
export var reviewCard = "styles-module--reviewCard--+TkVI";
export var reviewCardBody = "styles-module--reviewCardBody--skGzJ";
export var scaleUp = "styles-module--scaleUp--ihHoS";
export var slideIn = "styles-module--slideIn---SrBY";
export var sliderContainer = "styles-module--sliderContainer--cMnvY";