import React from "react";
import Body from "../../components/Body";
import StarRatings from "../../components/StarRatings";
import * as styles from "./styles.module.scss";
import { Link } from "gatsby";
import cx from "classname";
import Headline from "../Headline";

export default function ReviewCard({
  theme,
  rating,
  body,
  title,
  footer,
  footerUrl,
  className,
  bodyClassName
}) {
  return (
    <div className={cx(styles.entry, styles[theme], className)}>
      <div className={styles.ratingContainer}>
        <StarRatings rating={rating} />
      </div>
      {title && (
        <Headline tagType="h3" type="s24" className={styles.headline}>
          {title}
        </Headline>
      )}
      <Body richText={body} className={cx(styles.body, bodyClassName)} />
      {footerUrl ? (
        <Link to={footerUrl} className={styles.cta}>
          {footer}
        </Link>
      ) : (
        <div className={styles.footer}>{footer}</div>
      )}
    </div>
  );
}
