import React, { useEffect, useState } from "react";
import Headline from "../../components/Headline";
import SiteGrid from "../../components/SiteGrid";
import SiteGutter from "../../components/SiteGutter";
import Body from "../../components/Body";
import * as styles from "./styles.module.scss";
import Form from "./Form";
import useFocusById from "../../effects/useFocusById";
import unboxedDeviceMobile from "./assets/unboxedDeviceMobile.jpg";

export default function Contact({ data }) {
  const [isSuccess, setSuccess] = useState(false);

  return (
    <div className={styles.container} id="contact">
      <SiteGutter light>
        <SiteGrid>
          <div className={styles.grid}>
            <div className={styles.left}></div>
            <div className={styles.right}>
              <div>
                {isSuccess ? (
                  <Success data={data} />
                ) : (
                  <>
                    <Headline type="s32f" className={styles.headline}>
                      {data.headline}
                    </Headline>
                    <Body richText={data.body} className={styles.body} />
                    <Form data={data} onSuccess={() => setSuccess(true)} />
                    <div className={styles.imageContainer}>
                      <img
                        src={unboxedDeviceMobile}
                        alt="unboxed cove device"
                        loading="lazy"
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </SiteGrid>
      </SiteGutter>
    </div>
  );
}

function Success({ data }) {
  const focusById = useFocusById();

  useEffect(() => {
    setTimeout(() => {
      focusById("contact-form-success");
    }, 0);
  }, [focusById]);

  return (
    <div
      tabIndex={-1}
      id="contact-form-success"
      className={styles.contactFormSuccess}
    >
      <Headline type="s32f" className={styles.headline}>
        {data.headlineSuccess}
      </Headline>
      <Body richText={data.bodySuccess} className={styles.body} />
    </div>
  );
}
