import React from "react";
import SiteGrid from "../../components/SiteGrid";
import SiteGutter from "../../components/SiteGutter";
import * as styles from "./styles.module.scss";
import Body from "../../components/Body";
import Headline from "../../components/Headline";
import cx from "classname";

import orb from "./assets/orb.svg";
import biometricsScreenshot from "./assets/biometricsScreenshot.png";
import surveryScreenshot from "./assets/surveryScreenshot.png";
import biometricsMobileScreenshot from "./assets/biometricsMobileScreenshot.png";
import surveyMobileScreenshot from "./assets/surveyMobileScreenshot.png";

export default function TrackProgress({ data }) {
  return (
    <div className={styles.container}>
      <SiteGutter light>
        <SiteGrid>
          <div className={styles.grid}>
            <div className={styles.content}>
              <Headline className={styles.headline} type="s32f">
                {data.headline}
              </Headline>
              <Body richText={data.body} className={styles.body} />
              <a
                className={cx("button yellow mobileFull", styles.link)}
                href={data.cta.url}
              >
                {data.cta.label}
              </a>
            </div>
            <div>
              <div
                className={cx(styles.mobileScreenshotContainer, "mobileOnly")}
              >
                <img
                  src={surveyMobileScreenshot}
                  alt="survey screenshot"
                  loading="lazy"
                />
                <img
                  src={biometricsMobileScreenshot}
                  alt="biometrics screenshot"
                  loading="lazy"
                />
              </div>
              <div className={styles.screenshotContainer}>
                <img
                  src={biometricsScreenshot}
                  alt="biometrics screenshot"
                  loading="lazy"
                />
                <img
                  src={surveryScreenshot}
                  alt="survey screenshot"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </SiteGrid>
      </SiteGutter>
      <img
        src={orb}
        className={cx(styles.orb, "desktopOnly")}
        alt=""
        loading="lazy"
      />
    </div>
  );
}
