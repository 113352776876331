import React, { useEffect } from "react";
import BasePage from "../components/BasePage";
import Hero from "./Hero";
import Contact from "./Contact";
// import Wellbeing from "./Wellbeing";
// import Empower from "./Empower";
import Testimonials from "./Testimonials";
import RobustResults from "./RobustResults";
import TrackProgress from "./TrackProgress";
import PressBanner from "../Home/PressBanner";
import useAnalytics, { pageView } from "../effects/useAnalytics";

const sliceMap = {
  Hero,
  PressBanner,
  Contact,
  // Wellbeing,
  // Empower,
  Testimonials,
  RobustResults,
  TrackProgress
};

export default function Home({ pageContext, location }) {
  const analytics = useAnalytics();
  useEffect(() => {
    pageView("Employers Viewed");
    analytics.track("Employers Viewed", {
      referrer: document.referrer
    });
  }, [analytics]);

  return (
    <>
      <BasePage pageContext={pageContext} location={location}>
        {Object.keys(pageContext.slices).map((sliceName) => {
          const sliceData = pageContext.slices[sliceName];
          const SliceComponent = sliceMap[sliceName];

          if (SliceComponent) {
            return <SliceComponent key={sliceName} data={sliceData} />;
          } else {
            return null;
          }
        })}
      </BasePage>
    </>
  );
}
