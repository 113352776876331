function focusById(id) {
  const el = document.getElementById(id);
  if (el && el.focus) {
    el.focus();
  }
  return el;
}

function useFocusById() {
  return focusById;
}

export default useFocusById;
